import logo1 from "../Images/ClintsSliderImages/logo1.webp";
import logo2 from "../Images/ClintsSliderImages/logo2.png";
import logo3 from "../Images/ClintsSliderImages/logo3.webp";
import logo4 from "../Images/ClintsSliderImages/logo4.png";
import logo5 from "../Images/ClintsSliderImages/logo5.png";
import logo6 from "../Images/ClintsSliderImages/logo6.png";
import logo7 from "../Images/ClintsSliderImages/logo7.png";
import logo8 from "../Images/ClintsSliderImages/logo8.png";
import logo9 from "../Images/ClintsSliderImages/logo9.png";
import logo10 from "../Images/ClintsSliderImages/logo10.png";
import logo11 from '../Images/ClintsSliderImages/logo11.png';
import logo12 from "../Images/ClintsSliderImages/logo12.png";
import logo13 from "../Images/ClintsSliderImages/logo14.gif";
import logo14 from "../Images/ClintsSliderImages/logo15.png";
import logo15 from "../Images/ClintsSliderImages/logo16.png";

import ClintsSliderMainComponent from "../../Component/ClintsSliderMainComponent";

import * as React from "react";

const clintsImagesList = [
  {
    id: 1,
    src: logo1,
    alt: "Image 1",
    name: "Special Night Vision Camera",
    discription:
      " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 2,
    src: logo2,
    alt: "Image 2",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 3,
    src: logo3,
    alt: "Image 3",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 4,
    src: logo4,
    alt: "Image 4",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 5,
    src: logo5,
    alt: "Image 5",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 6,
    src: logo6,
    alt: "Image 6",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 7,
    src: logo7,
    alt: "Image 7",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 8,
    src: logo8,
    alt: "Image 8",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 9,
    src: logo9,
    alt: "Image 9",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 10,
    src: logo10,
    alt: "Image 9",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 11,
    src: logo11,
    alt: "Image 9",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 12,
    src: logo12,
    alt: "Image 9",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  
  {
    id: 13,
    src: logo13,
    alt: "Image 9",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 14,
    src: logo14,
    alt: "Image 9",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
  {
    id: 15,
    src: logo15,
    alt: "Image 9",
    name: "Special Night Vision Camera",
    discription: " During an archaeological expedition on Bouvetøya Island in Antarctica, a team of archaeologists and other scientists find themselves caught up in a battle ...",
  },
 
];



      
      function ClintsSliderComponent({ images, heading }) {
        return (
          <div>


            <ClintsSliderMainComponent images={clintsImagesList} heading={"Cameras"} />
            
          </div>
        );
      }
  


export default ClintsSliderComponent;
