import { Grid } from "@mui/material";
import SliederComponent from "../Component/SliderMainComponent";
import icon1 from "../myComponents/Images/FaltuIcons/cctv icon.png";
import icon2 from "../myComponents/Images/FaltuIcons/Transmission icon.png";
import icon3 from "../myComponents/Images/FaltuIcons/storage icon.png";
import icon4 from "../myComponents/Images/FaltuIcons/analise icon.png";
import icon5 from "../myComponents/Images/FaltuIcons/Vertical icon.png";
import SliderImagee1 from "../myComponents/Images/Slider Images/SliderImage1.png";
import SliderImagee2 from "../myComponents/Images/Slider Images/SliderImage2.png";
import SliderImagee3 from "../myComponents/Images/Slider Images/SliderImage3.png";
import SliderImagee4 from "../myComponents/Images/Slider Images/SliderImage4.png";
import SliderImagee5 from "../myComponents/Images/Slider Images/SliderImage5.png";
import React from "react";
import { Helmet } from "react-helmet";

const images = [
  SliderImagee1,
  SliderImagee2,
  SliderImagee3,
  SliderImagee4,
  SliderImagee5,
];

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>Welcome To V-SECURE</title>
        <meta name="description" content="We Provide all type of CCTV Camera Solutions." />
        <meta name="keywords" content="v-secure, V-SECURE, v secure, v-secure-bharat, v-secure cctv camera, cctv, v-secure camera, v-secure cctv, v secure cameras, V Secure, 5 mp bullet camera v-secure, 5 mp dome camera v-secure, 4 mp dome camera v-secure, 4 mp bullet camera v-secure,VSI-50B-CA-M vsecure,VSI-50D-CA-M vsecure,VSH-20D-C vsecure,VSH-40D-CA-P vsecure,VSH-40B-CA-P vsecure,VSH-20B-C vsecure,VSH-40V-CA-M vsecure,VSH-20B-N vsecure,VSH-20W.C vsecure,VSH-20D.M vsecure,VSH-20F.M vsecure,VSH-20W.C-ROBO vsecure,VSH-20W.C-ROBO,VSH-20F.M,VSH-20D.M,VSH-20W.C,VSH-20B-N,VSH-40V-CA-M,VSH-20B-C,VSH-40B-CA-P,VSH-40D-CA-P,VSH-20D-C,VSI-50D-CA-M,VSI-50B-CA-M, cctv cameras in rajasthan,Made in India CCTV" />
      </Helmet>

    <Grid container spacing={3} className="p-1 ">
      <Grid className="flex" item xs={12}>
        <SliederComponent images={images} />
      </Grid>

      <Grid item xs={12} md={6}>
  <div className="relative bg-gray-300 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 h-full flex flex-col">
    <img
      className="rounded-lg object-cover h-72 w-full"
      src="https://images.pexels.com/photos/374103/pexels-photo-374103.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
      alt="V-Secure"
      />
    <div className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-black to-transparent rounded-lg p-4">
      <h1 className="text-3xl font-extrabold text-white text-center">
        V-SECURE
      </h1>
    </div>
  </div>
</Grid>


      <Grid item xs={12} md={6}>
        <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 h-full flex flex-col justify-between">
          <h1 className="text-center text-2xl font-bold">Service & Support</h1>
          <p className="mt-4 text-justify text-gray-700">
            V-Secure has established a network for after-sales service &
            support all over the distribution channel. The qualified and
            experienced service experts provide the quickest and best service to
            you. Moreover, V-Secure has been conducting training for after-sales
            service engineers through regular training in the place of overseas
            partners. V-Secure also provides after-sales service training to
            buyers all over India and offers professional security and
            surveillance system solutions to meet various customers' demands.
          </p>
        </div>
      </Grid>

      <Grid item xs={12} className="text-center mt-10">
        <h1 className="text-3xl font-extrabold">CORE BUSINESS</h1>
        <h2 className="text-lg mt-2">Complete Solutions for Security Needs</h2>
        <p className="mt-4 text-gray-700">
          V-Secure products and solutions synergize intelligent technologies in
          every vertical application. We provide seamless integration of system
          components, resulting in an exceptional user experience. From data
          perception, transmission, storage, and management to analysis and
          most importantly, action. We create real-world solutions—a true
          "one-stop-shop" for the security needs of a broad spectrum of
          industries.
        </p>
      </Grid>

      <Grid item xs={12} className="mt-10">
        <Grid container spacing={3} justifyContent="center">
          {[
            { icon: icon1, title: "PERCEPTION", text: "Front-end multi-dimensional, stereo, and intelligent perception" },
            { icon: icon2, title: "TRANSMISSION", text: "H.265+ smart codec, PoE 2.0 PoC power" },
            { icon: icon3, title: "STORAGE", text: "Back-end NVR/DVRs, storage devices" },
            { icon: icon4, title: "ANALYSIS", text: "VMS, big data, cloud technology, cloud computing, video analytics, AI" },
            { icon: icon5, title: "VERTICAL SOLUTIONS", text: "Smart applications" },
          ].map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={2}>
              <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl hover:bg-blue-800 hover:text-white transition-all duration-300 text-center h-full flex flex-col justify-between">
                <img src={item.icon} alt={item.title} className="h-10 w-10 mx-auto mb-4" />
                <h1 className="text-lg font-bold">{item.title}</h1>
                <p className="mt-2 text-sm">{item.text}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12} md={6} className="mt-10">
        <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 h-full flex flex-col justify-between">
          <h1 className="text-center text-2xl font-bold">V-Secure Vertical Solutions</h1>
          <p className="mt-4 text-justify text-gray-700">
            In this journey of 11 years, we have catered to thousands of
            corporate clients across multiple industry verticals. Some of our
            clients are top brands from the automobile sector, education sector,
            hospital & healthcare, hospitality & hotels, government
            organizations, and many more. Indian Army, CRPF, CBI (Central Bureau
            of Investigation), Ministry of Mines, Income Tax Department, and
            many more are some of our elite clients whom we have served to
            ensure safety & security in their premises.
          </p>
        </div>
      </Grid>

      <Grid item xs={12} md={6} className="mt-10">
  <div className="relative bg-gray-900 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-300 text-white h-full flex flex-col">
    <img
      className="rounded-lg object-cover h-72 w-full"
      src="https://images.pexels.com/photos/10932824/pexels-photo-10932824.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
      alt="सुरक्षा आपकी, संकल्प हमारा"
      />
    <div className="absolute inset-0 flex items-end bg-gradient-to-t from-black to-transparent rounded-lg p-4">
      <h1 className="w-full text-center text-2xl font-extrabold   px-4 py-2 rounded-lg">
        सुरक्षा आपकी, संकल्प हमारा
      </h1>
    </div>
  </div>
</Grid>


    </Grid>
      </div>
  );
}
