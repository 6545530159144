import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function GalleryComponent({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dockStartIndex, setDockStartIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    if (images && images.length > 0) {
      setDockStartIndex(0);
    }
  }, [images]);

  const goToNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
    setDockStartIndex((prevIndex) =>
      prevIndex === images.length - 9
        ? 0
        : Math.min(prevIndex + 1, images.length - 9)
    );
  };

  const goToPreviousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    setDockStartIndex((prevIndex) =>
      prevIndex === 0 ? Math.max(0, images.length - 9) : prevIndex - 1
    );
  };

  const handleDockScroll = () => {
    const currentIndexInView = Math.min(currentIndex, dockStartIndex + 4);
    setDockStartIndex(Math.max(0, currentIndexInView - 4));
  };

  const handleImageHover = (index) => {
    setHoveredIndex(index);
  };

  return (
    <Grid container md={12} xs={12} sm={12} lg={12}>
      <Grid item md={12} xs={12} sm={12} lg={12}>
        <div className="flex items-center justify-center bg-gradient-to-r from-sky-500 to-indigo-500 h-24 rounded-lg text-white m-5">
          <Typography variant="h4" align="center" gutterBottom>
            Gallery
          </Typography>
        </div>
      </Grid>
      <Grid item md={12} xs={12} sm={12} lg={12}>
        <Grid container md={12} xs={12} sm={12} lg={12} className="flex justify-center">
          <Grid item md={6} xs={12} sm={12} lg={12} className="rounded-xl">
            <div className="text-md flex justify-center font-semibold p-2 text-nowrap">
              <h1>{images[currentIndex].name}</h1>
            </div>
            <div className="text-sm flex items-center text-black justify-center p-2 h-36 border shadow-md">
              <h1>{images[currentIndex].discription}</h1>
            </div>
          </Grid>

          <Grid item md={6} xs={12} sm={12} lg={12}>
            <Grid container md={12} xs={12} sm={12} lg={12} className="m-2">
              <Grid item md={1} xs={1} sm={1} lg={1} className="flex items-center justify-center">
                <div
                  className="h-full w-full flex bg-cyan-600 items-center justify-center border cursor-pointer shadow-md m-1"
                  onClick={goToPreviousImage}
                >
                  <ArrowBackIosNewIcon />
                </div>
              </Grid>

              <Grid item md={10} xs={10} sm={10} lg={10} className="bg-gray-200 rounded-lg">
                <div className="w-full h-72">
                  <img
                    className="object-contain w-full h-full"
                    src={images[currentIndex].src}
                    alt={images[currentIndex].alt}
                  />
                </div>
              </Grid>

              <Grid item md={1} xs={1} sm={1} lg={1} className="flex items-center justify-center">
                <div
                  className="h-full w-full bg-cyan-600 flex items-center justify-center border cursor-pointer shadow-md m-1"
                  onClick={goToNextImage}
                >
                  <ArrowForwardIosIcon />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12} sm={12} xs={12} xl={12}>
            <Grid container md={12} sm={12} xs={12} xl={12} className="items-center justify-center border hover:shadow-md m-1 p-5 rounded-lg flex">
              {images.slice(dockStartIndex, Math.min(dockStartIndex + 6, images.length)).map((image, index) => (
                <Grid key={index} item md={2} sm={2} xs={2} xl={2} className="flex items-center justify-center rounded-lg">
                  <div className="relative">
                    <img
                      className={`object-contain h-14 w-16 rounded-lg border shadow-md cursor-pointer transition-transform duration-300 ease-in-out transform ${
                        currentIndex === dockStartIndex + index ? "border-1 border-b-yellow-400" : ""
                      }`}
                      src={image.src}
                      alt={image.alt}
                      onClick={() => setCurrentIndex(dockStartIndex + index)}
                      onMouseEnter={() => handleImageHover(dockStartIndex + index)}
                      onMouseLeave={() => handleImageHover(null)}
                      style={{
                        transform: hoveredIndex === dockStartIndex + index ? "scale(2)" : "scale(1)",
                        zIndex: hoveredIndex === dockStartIndex + index ? 999 : 1,
                      }}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GalleryComponent;
