import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import { Button, Grid, Snackbar } from "@mui/material";
import * as React from "react";

export default function Types() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const saveData = () => {
    // Perform validation for email and phone number
    const phoneRegex = /^[0-9]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!phone.match(phoneRegex)) {
      alert("Please enter a valid phone number.");
      return;
    }

    if (!email.match(emailRegex)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Data sent successfully, clear form and show notification
    setName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setMessage("");
    setOpenSnackbar(true);

    // Fetch request to send data to Google Sheets
    fetch(
      "https://script.google.com/macros/s/AKfycbxIB6sXWgzfAU2TrgQvdE4X_7d9CVhhh1yWNTEaM2fMxBkEuBZ0ldZ5SxLfqhBbd0aNHg/exec",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors",
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          subject: subject,
          message: message,
        }),
      }
    )
      .then((response) => response.text())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));
  };

  return (
    <>
      <Grid container md={12} xs={12} sm={12} xl={12} className="p-4">
        <Grid item md={12} xs={12} sm={12} xl={12} className=" h-20"></Grid>
        <Grid item md={12} xs={12} sm={12} xl={12}>
          <div className="flex items-center justify-center  bg-gradient-to-r from-sky-500 to-indigo-500 h-24 rounded-lg text-white m-1">
            <h1>Contact Us</h1>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <div className="leftBox bg-sky-900  border-gray-200 text-white   p-5  rounded-lg m-1">
            <Box sx={{ height: "100%" }}>
              <div className=" p-1 m-1 text-center text-xl font-extrabold ">
                <h1  >
                  <strong>Contact Details:</strong>
                </h1>
              </div>
              <div className=" p-3 my-2">
                <h2 className="p-3" >
                  <strong> Head Office & Marketing Office</strong>
                </h2>

                <p className="p-4" >
                  105 C2 Rk Puram, Choudhary Nagar Dhanori, PUNE (MH.) Pin Code:
                  411015
                </p>
              </div>
             
              <div className=" p-3 my-2">
                <h2 className="p-3" >
                  <strong> Email Us:</strong>
                </h2>

                <p className="p-4" >
                  vsecurebharat@gmail.com
                </p>
              </div>
            </Box>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={9} xl={9}>
          <div className=" m-10">
            <Grid container spacing={5}>
              <Grid item sm={12} xs={12} md={6} xl={6}>
                {" "}
                <TextField
                  value={name}
                  id="outlined-basic"
                  className=" m-9 p-10 "
                  fullWidth
                  label="Name"
                  variant="outlined"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={6} xl={6}>
                {" "}
                <TextField
                value={email}
                  id="outlined-basic"
                  className=" m-9 p-10 "
                  fullWidth
                  label="Email"
                  variant="outlined"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={6} xl={6}>
                {" "}
                <TextField
                value={phone}
                  id="outlined-basic"
                  className=" m-9 p-10 "
                  fullWidth
                  label="Phone"
                  variant="outlined"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={6} xl={6}>
                {" "}
                <TextField
                value={subject}
                  id="outlined-basic"
                  className=" m-9 p-10 "
                  fullWidth
                  label="Subject"
                  variant="outlined"
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12} xl={12}>
                {" "}
                <TextField
                value={message}
                  id="outlined-basic"
                  className=" m-2 p-2"
                  fullWidth
                  label="Message"
                  multiline
                  minRows={5}
                  variant="outlined"
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </div>

          <Grid item xs={12} md={4} lg={2}>
            <div className=" p-3 m-10">
              <Button
                size="large"
                variant="contained"
                endIcon={<SendIcon />}
                onClick={saveData}
              >
                Send
              </Button>
            </div>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} xs={12} xl={12}>
          <div className="mapouter p-1 bg-gray-900 border-gray-200   text-white  h-full text-center rounded-lg ">
            
            <h1 className="p-2 text-2xl font-bold">
              <strong>V-Secure</strong>
            </h1>
            <div className="gmap_canvas m-1">
              <p className="p-3">
                Head Office & Marketing Office
              </p>
              <form noValidate autoComplete="off">
                <iframe
                  className="gmap_iframe  w-full h-96 rounded-lg "
                  src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=105  PUNE (MH.) Pin Code:
                  411015 &amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* Snackbar for notification */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message="Message sent successfully!"
      />
    </>
  );
}
