import React from "react";
import { Grid } from "@mui/material";
import Navbar from "../Something/Navbar";
import Footer from "../Something/Footer";
import Home from "../Home";
import Products from "../Products";
import Gallery from "../Gallary";
import Contact from "../Contact";
import About from "../About";
// import { Helmet } from "react-helmet";

function MainScreen() {
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>


    <Grid container spacing={7}  >
    <Grid item xs={12} className="z-50 top-0">
      <Navbar scrollTo={scrollTo} />
    </Grid>

    <Grid  item xs={12} >
      <Grid container  >
        <Grid item xs={12} id="home">
          <Home />
        </Grid>
        <Grid item xs={12} id="products">
          <Products />
        </Grid>
        <Grid item xs={12} id="gallery">
          <Gallery />
        </Grid>
        
        <Grid item xs={12} id="contact">
          <Contact />
        </Grid>
        <Grid item xs={12} id="about">
          <About />
        </Grid>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
    </div>
    
  );
}

export default MainScreen;
