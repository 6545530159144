import ClintsSliderComponent from "./Something/ClintsSliderComponent";
import { Grid } from "@mui/material";
import storeLocation from "../myComponents/Images/storeLocation.jpeg";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import React from "react";

export default function About() {
  return (
    <Grid container md={12} xs={12} sm={12} xl={12} className=" text-wrap ">
      <Grid item md={12} xs={12} sm={12} xl={12} className=" h-20"></Grid>

      <Grid item md={12} xs={12} sm={12} lg={12}>
        <Grid
          container
          md={12}
          xs={12}
          sm={12}
          lg={12}
          className=" gap-5  text-wrap"
        >
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            lg={12}
            className=" items-center text-center"
          >
            <div className=" bg-gradient-to-r from-sky-500 to-indigo-500 text-white m-4 p-4 h-24 flex items-center justify-center rounded-lg">
              <strong>About</strong>
            </div>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            lg={12}
            className=" p-7 flex items-center border shadow-xl "
          >
            <div>
              <p>
                We V-Secure, established in 2012 are well known manufacturer of
                Digital & Analog Electronic Equipment. The offered range of
                products incorporates CCTV Cameras, Video Recorders, Security
                Alarm Systems . Offered security and surveillance systems are
                widely used in the offices, residential, commercial areas,
                airports, railway stations and other places. Offered products
                are highly efficient and user friendly.
              </p>
            </div>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            lg={12}
            className="p-7  flex items-center border shadow-xl"
          >
            <div>
              <p>
                V-Secure committed to its clients and has earned a favorable
                reputation in the markets with its high-quality products and
                efficient services to its customers. To bring best quality
                product we use high quality raw materials, sophisticated tools
                and cutting-edge technology in the manufacturing of these
                equipment’s. Also, we employ advanced machinery to manufacture
                best quality products. For ensuring their optimum quality,
                offered products are rigorously checked on various parameters by
                our qualified team of professionals.
              </p>
            </div>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            lg={12}
            className=" p-7 flex items-center border shadow-xl"
          >
            <div>
              <p>
                We always strive to bring latest and modern technology to
                provide our customers innovative and Hi- Tech products. Further,
                we have a highly qualified and trained group of experienced
                professionals, which continuously put its efforts for developing
                new products, to meet the requirements of clients. We thrive on
                qualities such as truthfulness, integrity, reliability and hard
                work that have resulted in the prosperity of every associate as
                well as in the exponential growth of the company itself.
              </p>
            </div>
          </Grid>
          <Grid item md={12} className=" p-4">
            <Grid container md={12} className="    ">
              <Grid item md={4} className="flex justify-center items-center">
                <Grid
                  container
                  md={10}
                  className=" bg-orange-500 text-white p-4 hover:bg-gray-500  rounded-lg "
                >
                  <Grid
                    item
                    md={12}
                    className=" flex items-center justify-center"
                  >
                    <div>
                      <AdsClickIcon />
                    </div>
                    <div className="p-4">OUR VISION</div>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    className=" flex items-center justify-center"
                  >
                    <div className="p-4">
                      <p>
                        To stay committed towards a safer India through
                        indigenous manufacturing, cutting edge technologies,
                        best-in-class products, and solutions while focusing on
                        research, technology innovation, capacity building, and
                        manufacturing excellence. To be a leader in the security
                        market and make India a global manufacturing hub for
                        cutting-edge surveillance products.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={4} className="flex justify-center items-center">
                <Grid
                  container
                  md={10}
                  className=" bg-blue-800 text-white p-4 hover:bg-gray-500 h-full rounded-lg "
                >
                  <Grid
                    item
                    md={12}
                    className=" flex items-center justify-center"
                  >
                    <div>
                      <SettingsAccessibilityIcon />
                    </div>
                    <div className="p-4">OUR MISSION</div>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    className=" flex items-center justify-center"
                  >
                    <div className="p-4">
                      <p>
                        Building India’s security and surveillance industry by
                        continuously enhancing capabilities in domestic
                        production by investing in local R&D to foster
                        innovation in next-generation surveillance solutions. To
                        make India self–reliant through local manufacturing
                        Socio-economic empowerment, skill development, and
                        providing employment.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={4} className="flex justify-center items-center w-full">
                <Grid
                  container
                  md={10}
                  className=" bg-green-500 text-white p-4 hover:bg-gray-500 h-full  rounded-lg  "
                >
                  <Grid
                    item
                    md={12}
                    className=" flex items-center justify-center"
                  >
                    <div>
                      <VolunteerActivismIcon />
                    </div>
                    <div className="p-4 ">OUR VALUES</div>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    className=" flex items-center justify-center "
                  >
                    <div className="p-1 ">
                      <h1>• Self-Reliance</h1>
                      <h1>• Excellence</h1>
                      <h1>• Integrity</h1>
                      <h1>• Ethics</h1>
                      <h1>• Empowerment</h1>
                      <h1>• Humility</h1>
                      <h1>• Nation-building</h1>
                      <h1>• Trust</h1>
                      <h1>• Commitment</h1>
                      <h1>• Ownership</h1>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            lg={12}
            className=" p-7 flex items-center border shadow-xl"
          >
            <div>
              <p>
                We always guides the people associated with us to reflect
                perfection in all the tasks assigned to them. Adhering to
                ethical business policies coupled with focus on client centric
                approach, we have established a reputed name as a leading
                manufacturer, suppliers of numerous security products. lets
                together build India A safer nation with V-Secure security
                products.
              </p>
            </div>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
            sm={12}
            lg={12}
            className="p-7  flex items-center border shadow-xl"
          >
            <div>
              <p>
                We, V-Secure as a team is continuously working hard with extra
                effort to reach the milestone with flying success. We are more
                confident to win the race with every new joining hand in our
                success. The major role behind our success is our Esteemed and
                Loving clients who believe us as “The Best”
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12} xs={12} sm={12} lg={12}>
        <div className="p-4 flex items-center justify-center ">
          <img className=" rounded-lg" src={storeLocation} alt="Our Stores" />
        </div>
      </Grid>
      <Grid item md={12} xl={12} sm={12} xs={12}>
        <div className=" p-4">
          <ClintsSliderComponent />
        </div>
      </Grid>
    </Grid>
  );
}
