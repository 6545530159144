import React from 'react';
import Product1 from "../myComponents/Products/Product1";
import GroupsIcon from '@mui/icons-material/Groups';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import FactoryIcon from '@mui/icons-material/Factory';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { Grid } from "@mui/material";

export default function Products() {
  return (
    <div>
<div className='h-20'>

</div>
    <Grid container spacing={3} className="">
      {/* Header */}
      <Grid item xs={12} className="text-center mb-4">
        <div className='bg-gradient-to-r from-sky-500 to-indigo-500 text-white p-6 rounded-lg'>
          <h1 className="text-2xl font-bold">Products</h1>
        </div>
      </Grid>

      {/* Intro */}
      <Grid item xs={12} className="text-center mb-6">
        <h2 className='font-bold text-lg'>
          We Provide a wide Range of surveillance Products to ensure proper surveillance
        </h2>
      </Grid>

      {/* Product Component */}
      <Grid item xs={12} className='mb-6'>
        <Product1 />
      </Grid>

      {/* Why Us Section */}
      <Grid item xs={12}>
        <div className='bg-orange-500 text-white p-6 rounded-lg'>
          <h1 className='text-center text-2xl font-extrabold mb-4'>Why Us</h1>
          <Grid container spacing={3}>
            {[
              { Icon: GroupsIcon, text: 'HIGHLY MOTIVATED TEAM' },
              { Icon: VerifiedUserIcon, text: 'QUALITY ASSURANCE' },
              { Icon: SettingsSuggestIcon, text: 'LATEST TECHNOLOGIES' },
              { Icon: TipsAndUpdatesIcon, text: 'INNOVATIVE AND CUSTOMIZED SOLUTIONS' },
              { Icon: FactoryIcon, text: 'WORLD-CLASS MANUFACTURING FACILITY' },
              { Icon: WidgetsIcon, text: 'WIDE RANGE OF PRODUCTS' }
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} className='text-center'>
                <div className='bg-green-800 p-4 rounded-lg shadow-md hover:bg-blue-800 hover:shadow-lg transition-all'>
                  <item.Icon sx={{ fontSize: 52 }} className="mb-2" />
                  <h2 className='font-semibold'>{item.text}</h2>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
    </Grid>
            </div>
  );
}
