import React, { useState } from "react";
import { Grid, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../Images/Subject.jpg";

function Navbar({ scrollTo }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const handleScrollTo = (section) => {
    scrollTo(section);
    toggleDrawer(false); // Close drawer on selection
  };

  return (
    <Grid
      container
      className="p-4 bg-gray-900 text-yellow-300 flex justify-between items-center shadow-2xl fixed left-0 right-0 "
    >
      {/* Logo and Brand */}
      <Grid item xs={6} sm={6} md={4} lg={4}>
        <Grid container alignItems="center">
          <Grid item>
            <button onClick={() => scrollTo("home")}>
              <img
                src={logo}
                alt="Logo"
                className="h-10 w-10 hover:animate-bounce"
              />
            </button>
          </Grid>
          <Grid item>
            <div className="ml-2 text-white">
              <button
                onClick={() => scrollTo("home")}
                className="group hover:underline text-xl font-bold"
              >
                - SECURE
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {/* Navigation Links - Hidden on small screens */}
      <Grid item xs={0} sm={0} md={8} lg={8} className="hidden md:flex">
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
        >
          {["home", "products", "gallery", "contact", "about"].map((section) => (
            <Grid item key={section}>
              <button
                onClick={() => scrollTo(section)}
                className="nav-link px-4 py-2 text-white font-semibold hover:text-yellow-400 transition-colors"
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </button>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Hamburger Menu for Mobile Devices */}
      <Grid item xs={6} sm={6} md={0} lg={0} className="flex justify-end  md:hidden">
        <IconButton onClick={() => toggleDrawer(true)} className="text-white">
          <MenuIcon className=" text-white" />
        </IconButton>
      </Grid>

      {/* Drawer for Mobile Navigation */}
      <Drawer className=" " anchor="right" open={drawerOpen} onClose={() => toggleDrawer(false)}>
        <List className="w-64 bg-gray-900 text-yellow-300">
          {["home", "products", "gallery", "contact", "about"].map((section) => (
            <ListItem button key={section} onClick={() => handleScrollTo(section)}>
              <ListItemText primary={section.charAt(0).toUpperCase() + section.slice(1)} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Grid>
  );
}

export default Navbar;
