import cam1 from "../Images/ProductImages/v-secure/Cam1.png";
import cam2 from "../Images/ProductImages/v-secure/Cam2.png";
import cam3 from "../Images/ProductImages/v-secure/Cam3.png";
import cam4 from "../Images/ProductImages/v-secure/Cam4.png";
import cam5 from "../Images/ProductImages/v-secure/Cam5.png";
import cam6 from "../Images/ProductImages/v-secure/Cam6.png";
import cam7 from "../Images/ProductImages/v-secure/Cam7.png";
import cam8 from "../Images/ProductImages/v-secure/Cam8.png";
import cam9 from "../Images/ProductImages/v-secure/Cam9.png";
import cam10 from "../Images/ProductImages/v-secure/Cam10.png";
import cam11 from "../Images/ProductImages/v-secure/Cam11.png";
import cam12 from "../Images/ProductImages/v-secure/Cam12.png";
import wire1 from "../Images/ProductImages/v-secure/Wire1.png";
import wire2 from "../Images/ProductImages/v-secure/Wire2.png";
import wire3 from "../Images/ProductImages/v-secure/Wire3.png";
import router1 from "../Images/ProductImages/v-secure/Box1.png";
import router2 from "../Images/ProductImages/v-secure/Box2.png";
import display1 from "../Images/ProductImages/v-secure/Display.jpeg";
import display2 from "../Images/ProductImages/v-secure/Display2.png";
import phone1 from "../Images/ProductImages/v-secure/Telephone1.png";
import phone2 from "../Images/ProductImages/v-secure/Telephone2.png";
import phone3 from "../Images/ProductImages/v-secure/Telephone3.png";
import phone4 from "../Images/ProductImages/v-secure/Telephone4.png";
import phone5 from "../Images/ProductImages/v-secure/Telephone5.png";
import ImagesComponent from "../../Component/ImagesComponent";
// import VerticalTabs from "../../Component/"

import * as React from "react"; 

const camImagesList = [
  {
    id: 1,
    src: cam1,
    alt: "Image 1",
    name: "5 MP IP Bullet Camera Colour + Audio ",
    discription:
      "• Model: VSI-50B-CA-M • Resolution: 5MP • Smart Dual Night Vision • Metal Body • Built-in Mic • ONVIF Support • POE Easy to connect • Fixed Lens 3.6mm • Power Voltage: 12V DC • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA",
     
  },
  {
    id: 2,
    src: cam2,
    alt: "Image 2",
    name: "5 MP IP Dome Camera Colour + Audio ",
    discription: "• Model: VSI-50D-CA-M • Resolution: 5MP • Smart Dual Night Vision • Metal Body • Built-in Mic • ONVIF Support • POE Easy to connect • Fixed Lens 3.6mm • Power Voltage: 12V DC • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA",
  },
  {
    id: 3,
    src: cam3,
    alt: "Image 3",
    name: "  2MP HD colour Dome Camera",
    discription: "• Model: VSH-20D-C  • HD Dome Camera  • Night Vision: 20mtr • Resolution: 2MP • Smart Dual Night Vision  • Built-in Mic   • Fixed Lens 3.6mm • Power Voltage: 12V DC • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA",
  },
  {
    id: 4,
    src: cam4,
    alt: "Image 4",
    name: "4 MP IP Dome Camera Colour + Audio",
    discription: "• Model: VSH-40D-CA-P  • Resolution: 4MP  • Metal Body • Built-in Mic  • Night Vision: 20mtr  • Fixed Lens : 2.8mm/ 3.6mm  • H.265+/H.265/H.264+/H.264 • ONVIF Support • POE Support Easy to connect • Field of view: 110 horizontal  • Bit rate control (VBR/CBR)  • Power Voltage: 12V DC   • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA" },
  {
    id: 5,
    src: cam5,
    alt: "Image 5",
    name: "4 MP IP Bullet Camera Colour + Audio",
    discription: "• Model: VSH-40B-CA-P  • Resolution: 4MP  • Metal Body • Built-in Mic  • Night Vision: 20mtr  • Fixed Lens : 2.8mm/ 3.6mm  • H.265+/H.265/H.264+/H.264 • ONVIF Support • POE Support Easy to connect • Field of view: 110 horizontal  • Bit rate control (VBR/CBR)  • Power Voltage: 12V DC   • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA" },
  
  {
    id: 6,
    src: cam6,
    alt: "Image 6",
    name: "2 MP HD Bullet Camera Colour",
    discription: "• Model: VSH-20B-C  • HD Bullet Camera  • Night Vision: 20mtr • Resolution: 2MP • Smart Dual Night Vision  • Built-in Mic   • Fixed Lens 3.6mm • Power Voltage: 12V DC • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA"  },
  {
    id: 7,
    src: cam7,
    alt: "Image 7",
    name: "4 MP IP Vendel Dome Camera inbuilt Audio",
    discription: "• Model: VSH-40V-CA-M  • Resolution: 4MP  • Metal Body • Built-in Mic  • Night Vision: 20mtr  • Fixed Lens : 2.8mm/ 3.6mm  • H.265+/H.265/H.264+/H.264 • ONVIF Support • POE Support Easy to connect • Field of view: 110 horizontal  • Bit rate control (VBR/CBR)  • Power Voltage: 12V DC   • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA" },
  
  {
    id: 8,
    src: cam8,
    alt: "Image 8",
    name: "2 MP HD Bullet Camera ",
    discription: "• Model: VSH-20B-N  • HD Bullet Camera  • Night Vision: 20mtr • Resolution: 2MP • Smart Dual Night Vision  • Built-in Mic   • Fixed Lens 3.6mm • Power Voltage: 12V DC • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA"  },
  
  {
    id: 9,
    src: cam9,
    alt: "Image 9",
    name: "2 MP Mini PT wifi Camera 360",
    discription: "• Model: VSH-20W.C  • HD Bullet Camera  • Night Vision: 20mtr • Resolution: 2MP • Smart Dual Night Vision  • Built-in Mic 2 way   • Fixed Lens 3.6mm • Power Voltage: 12V DC • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA"  },
  
  {
    id: 10,
    src: cam10,
    alt: "Image 10",
    name: "2 MP HD Dome Camera ",
    discription: "• Model: VSH-20D.M  • HD Bullet Camera  • Night Vision: 20mtr • Resolution: 2MP • Smart Dual Night Vision  • Built-in Mic 2 way   • Fixed Lens 3.6mm • Power Voltage: 12V DC • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA"  },
  
  {
    id: 11,
    src: cam11,
    alt: "Image 11",
    name: "2 MP HD Fisheye 360  Camera",
    discription: "• Model: VSH-20F.M  • Resolution: 2MP  • Lens Type: 1.8mm Fixed • Metal Body • Built-in Mic  • Night Vision: 20mtr   • Features: 165 Field-of-View • Min. Illumination: 0.01 Lux/F1.2 (IR ON) • Sensor CMOS Sensor   • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA"  },
  
  {
    id: 12,
    src: cam12,
    alt: "Image 12",
    name: "2 MP Smart Wifi Robot Camera",
    discription: "• Model: VSH-20W.C-ROBO  • HD Bullet Camera  • Night Vision: 20mtr • Resolution: 2MP • Smart Dual Night Vision  • Built-in Mic 2 way   • Fixed Lens 3.6mm • Power Voltage: 12V DC • 1 Year Replacement + 1 Year Repair Warranty • MAKE IN INDIA"  },
  
];

const wireImageList = [
  {
    id: 1,
    src: wire1,
    alt: "wire Image..",
    name: "Cat 6 Outdore wire CCA 180 meter",
    discription: " • Model No. VSC-CAT6- CCA OUT-100Y • No. of Pair 4• Length 100 yards",
   
  },
  {
    id: 2,
    src: wire2,
    alt: "Image 12",
    name: "3 + 1 HD camera copper wire",
    discription: " • Model No. VSC-3+1-Premium-100Y • No. of Pair 4• Length 100 yards",
  },
  {
    id: 3,
    src: wire3,
    alt: "Image 12",
    name: "3 + 1 HD camera copper wire",
    discription: " • Model No. VSC-3+1-Premium-100Y • No. of Pair 4• Length 100 yards",
  },
];
const routerImageList = [
  {
    id: 1,
    src: router1,
    alt: "router 1..",
    name: "V-SECURE 2MP 4CH XVR",
    discription: " V-SECURE 2MP 4CH XVR  1-VGA Port, 1 HDMI Port, 2 USB Port ",
    
  },
  {
    id: 2,
    src: router1,
    alt: "router 1..",
    name: "V-SECURE 2MP 8CH XVR",
    discription: " V-SECURE 2MP 8CH XVR 1-VGA Port, 1 HDMI Port, 2 USB Port",
  },
  {
    id: 3,
    src: router1,
    alt: "router 1..",
    name: "V-SECURE 2MP 16CH XVR",
    discription: " V-SECURE 2MP 16CH XVR 1-VGA Port, 1 HDMI Port, 2 USB Port",
  },
  {
    id: 4,
    src: router2,
    alt: "Router 2",
    name: "V-SECURE 4CH NVR upto 8 MP",
    discription: " V-SECURE 4CH NVR upto 8 MP 1-VGA Port, 1 HDMI Port, 2 USB Port",
  },
  {
    id: 5,
    src: router2,
    alt: "Router 2",
    name: "V-SECURE 8CH NVR upto 8 MP",
    discription: " V-SECURE 8CH NVR upto 8 MP 1-VGA Port, 1 HDMI Port, 2 USB Port",
  },
  {
    id: 6,
    src: router2,
    alt: "Router 2",
    name: "V-SECURE 16CH NVR upto 8 MP",
    discription: " V-SECURE 16CH NVR upto 8 MP 1-VGA Port, 1 HDMI Port, 2 USB Port",
  },
  {
    id: 7,
    src: router2,
    alt: "Router 2",
    name: "V-SECURE 32CH NVR upto 8 MP",
    discription: " V-SECURE 32CH NVR upto 8 MP 1-VGA Port, 1 HDMI Port, 2 USB Port",
  },
 
];
const phoneImageList = [
  {
    id: 1,
    src: phone1,
    alt: "Phone Image..",
    name: "V-SECURE VSPN0015",
    discription: " Corded Landline Phones For Home/Hotel/Office",
    
  },
  {
    id: 2,
    src: phone2,
    alt: "Phone Image..",
    name: "V-SECURE VSPN0016",
    discription: "  Corded Landline Phones For Home/Hotel/Office",
  },
  {
    id: 3,
    src: phone3,
    alt: "Phone Image..",
    name: "V-SECURE VSPN0017",
    discription: " High Quality Top Security Hotel Guestroom Telephone",
  },
  {
    id: 4,
    src: phone4,
    alt: "Phone Image..",
    name: "V-SECURE VSPN0018",
    discription: " Wall Mountable Telephone For Hotel",
  },
  {
    id: 5,
    src: phone5,
    alt: "Phone Image..",
    name: "V-SECURE VSPN0019",
    discription: " Communication Telephone Desktop/Wall Mounted For Home Office",
  },
];
const displayImageList = [
  {
    id: 1,
    src: display1,
    alt: "Display Image..",
    name: "Framless Smart LED TV",
    discription: " V-SECURE 32 inch Framless Smart LED TV,  1 Port:HDMI, 1 Port:VGA, 1 Port:AV ",
    
  }
  
];



      
      function ImagesComponents({ images, heading, headingImage }) {
        return (
          <div>
<div>

</div>

            <ImagesComponent images={camImagesList} heading={"Cameras"} headingImage={cam1} />
            <ImagesComponent images={wireImageList} heading={"Wires"} headingImage={wire1}  />
            <ImagesComponent images={routerImageList} heading={"NVR/XVR"} headingImage={router1}  />
            <ImagesComponent images={phoneImageList} heading={"Phones"} headingImage={phone2}  />
            <ImagesComponent images={displayImageList} heading={"Displays"} headingImage={display2}  />
          </div>
        );
      }
  


export default ImagesComponents;
