import React from 'react'

import { Grid } from "@mui/material";
import logo from "../Images/Subject.jpg";





export default function Footer() {
  return (
    <Grid
      container md={12} xs={12} sm={12} lg={12}
      
    >
    
      <Grid item md={12} sm={12} xs={12} xl={12}>
        <footer className=" bg-gray-900 text-white  px-4 sm:px-6 lg:px-8 py-6 lg:py-8 rounded-lg">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <a href="/" className="flex items-center">
                <img src={logo} className="h-8 me-3" alt="Logo" />
                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                  -SECURE
                </span>
              </a>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              {/*  footer links will be here */}
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              © 2023{" "}
              <a href="/" className="hover:underline">
                V-secure™
              </a>
              . All Rights Reserved.
            </span>
            <div className="flex mt-4 sm:justify-center sm:mt-0">
              {/*  social media links will be here */}
            </div>
          </div>
        </footer>
      </Grid>
    </Grid>
  )
}
