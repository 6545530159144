import GallarySecondComponents from "../myComponents/Products/GallarySecondComponent";
import { Grid } from '@mui/material'
import React from 'react'

export default function Gallary() {
  return (
    <Grid container md={12}>
<Grid item md={12} xs={12} sm={12} xl={12} className=" h-20">

</Grid>

      <Grid item md={12} className="p-1">

    <div className=" p-1">
      <GallarySecondComponents/>
    </div>
      </Grid>
      <Grid item md={12} className=" ">
      </Grid>

    </Grid>
  )
}
