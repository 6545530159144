import React, { useState, useEffect } from 'react';
import { Grid } from "@mui/material";

function ClintsSliderMainComponent({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const visibleImages = images.slice(currentIndex, currentIndex + 4);

  useEffect(() => {
    const timer = setTimeout(goToNextImage, 4000);
    return () => clearTimeout(timer);
  }, [currentIndex, images]);

  const goToNextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 4 ? 0 : prevIndex + 1
    );
  };

  return (
    <div>
      <Grid container md={12} xl={12} sm={12} xs={12}>
        {visibleImages.map((image, index) => (
          <Grid item md={3} xl={3} sm={3} xs={3}  key={index}>
            <div className="w-full h-72 flex items-center justify-center">
              <img
                className="h-full w-full object-contain p-4 m-4 "
                src={image.src}
                alt={image.alt}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default ClintsSliderMainComponent;
