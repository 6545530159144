import React, { useState, useEffect } from 'react';

const ImageSlider = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(true);

    useEffect(() => {
        if (isPlaying) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [currentIndex, images.length, isPlaying]);

    const goToNextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const goToPrevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const toggleAutoplay = () => {
        setIsPlaying(!isPlaying);
    };

    return (
        <div className="slider-container relative h-full w-full   ">
          
            <div className=" p-1  ">
                <img 
                    src={images[currentIndex]} 
                    alt={`Slide ${currentIndex}`} 
                    className="slider-image h-96 object-contain w-full rounded-lg transition-opacity duration-500 ease-in-out" 
                />
                <button
                    onClick={goToPrevSlide}
                    className="absolute top-1/2 transform -translate-y-1/2 left-0 bg-gray-800 text-white px-4 py-2 rounded-r hover:bg-gray-600 transition-colors"
                >
                    ❮
                </button>
                <button
                    onClick={goToNextSlide}
                    className="absolute top-1/2 transform -translate-y-1/2 right-0 bg-gray-800 text-white px-4 py-2 rounded-l hover:bg-gray-600 transition-colors"
                >
                    ❯
                </button>
                <button
                    onClick={toggleAutoplay}
                    className="absolute bottom-4 right-4 bg-gray-800 text-white px-3 py-1 rounded hover:bg-gray-600 transition-colors"
                >
                    {isPlaying ? 'Pause' : 'Play'}
                </button>
            </div>
            <div className="flex justify-center mt-4">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`h-3 w-3 mx-1 rounded-full cursor-pointer ${
                            index === currentIndex ? 'bg-blue-500' : 'bg-gray-300'
                        }`}
                        onClick={() => setCurrentIndex(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;
