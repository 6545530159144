import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Box, Grid } from '@mui/material';

function ImagesComponent({ images, heading, headingImage }) {
  const [selectedHeading, setSelectedHeading] = useState(0);

  useEffect(() => {
    setSelectedHeading(0);
  }, [images]);

  const handleHeadingClick = (index) => {
    setSelectedHeading(index);
  };

  return (
    <>
      {/* Heading Section */}
      <Box className="flex flex-col md:flex-row items-center justify-center p-4 border-b bg-gray-100">
        <img 
          className="h-24 w-32 md:h-36 md:w-48 object-contain mb-4 md:mb-0 md:mr-4" 
          src={headingImage} 
          alt={heading} 
        />
        <Typography 
          variant="h4" 
          className="text-center md:text-left font-semibold text-gray-800 text-xl md:text-3xl"
        >
          {heading}
        </Typography>
      </Box>

      {/* Images and Details Section */}
      <Box className="p-4 bg-white">
        <Grid container spacing={2}>
          {/* Horizontal List of Image Titles on Mobile */}
          <Grid 
            item 
            xs={12} 
            md={4} 
            className="relative" 
            style={{ maxHeight: '400px' }} // Set a fixed height for the list
          >
            <Box 
              className="flex md:flex-col space-x-2 md:space-x-0 md:space-y-2 overflow-x-auto md:overflow-y-auto scrollbar-hide"
              style={{ 
                whiteSpace: 'nowrap', 
                maxHeight: '100%', // Ensure the height is limited to the container
                overflowY: 'auto', // Enable vertical scrolling
              }}
            >
              {images.map((image, index) => (
                <Typography
                  key={index}
                  onClick={() => handleHeadingClick(index)}
                  className={`cursor-pointer p-2 rounded-md text-gray-800 hover:bg-blue-100 transition ${selectedHeading === index ? 'bg-blue-100 font-semibold' : ''}`}
                  style={{ display: 'inline-block', fontSize: '1rem', color: '#374151' }}
                >
                  {image.name}
                </Typography>
              ))}
            </Box>

            {/* Scroll indicator (gradient effect) */}
            <Box 
              className="absolute top-0 right-0 w-8 h-full pointer-events-none bg-gradient-to-l from-white to-transparent md:hidden"
            />
          </Grid>

          <Grid item xs={12} md={8}>
            {/* Image and Description Card */}
            <Card className="w-full shadow-md" style={{ height: '400px' }}> {/* Match the height with the list */}
              <CardMedia
                component="img"
                image={selectedHeading !== null ? images[selectedHeading].src : ''}
                alt={selectedHeading !== null ? images[selectedHeading].alt : ''}
                className="h-full w-full"
                style={{
                  objectFit: 'contain', // Ensure the entire image is visible
                  height: '200px',      // Set a fixed height to control aspect ratio
                  width: '100%'         // Ensure it takes up the full width of the card
                }}
              />
              <CardContent>
                <Typography 
                  variant="h6" 
                  className="font-semibold text-gray-800 text-lg md:text-xl"
                >
                  {selectedHeading !== null ? images[selectedHeading].name : ''}
                </Typography>
                <Typography 
                  variant="body2" 
                  className="text-gray-600 mt-2 text-base md:text-lg"
                >
                  {selectedHeading !== null ? images[selectedHeading].discription : ''}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ImagesComponent;
